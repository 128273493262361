@charset "UTF-8";
.index-banner .swiper-container {
  overflow: visible;
}

.index-banner .swiper-wrapper .swiper-slide {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 755px;
}

@media (max-width: 1600px) {
  .index-banner .swiper-wrapper .swiper-slide {
    height: 640px;
  }
}

@media (max-width: 1359px) {
  .index-banner .swiper-wrapper .swiper-slide {
    height: 410px;
  }
}

@media (max-width: 1023px) {
  .index-banner .swiper-wrapper .swiper-slide {
    height: 400px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-wrapper .swiper-slide {
    height: 512px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-wrapper .swiper-slide {
    height: 250px;
  }
}

.index-banner .swiper-wrapper .swiper-slide a {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.index-banner .swiper-wrapper .swiper-slide a.pc-banner {
  display: block;
}

@media (max-width: 991px) {
  .index-banner .swiper-wrapper .swiper-slide a.pc-banner {
    display: none;
  }
}

.index-banner .swiper-wrapper .swiper-slide a.mob-banner {
  display: none;
}

@media (max-width: 991px) {
  .index-banner .swiper-wrapper .swiper-slide a.mob-banner {
    display: block;
  }
}

.index-banner .swiper-wrapper .swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
}

.index-banner .swiper-pagination {
  display: flex;
  justify-content: center;
}

.index-banner .swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet {
    width: 15px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet {
    height: 15px;
  }
}

.index-banner .swiper-pagination .swiper-pagination-bullet:not(.swiper-pagination-bullet-active)::after {
  content: '';
  display: inline-block;
  border-radius: 50%;
  background-color: #f3e3c1;
  width: 8px;
  height: 8px;
}

@media (max-width: 767px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet:not(.swiper-pagination-bullet-active)::after {
    width: 5px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet:not(.swiper-pagination-bullet-active)::after {
    height: 5px;
  }
}

.index-banner .swiper-pagination .swiper-pagination-bullet svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform-origin: center;
  transform-box: fill-box;
  transform: rotate(-90deg);
}

.index-banner .swiper-pagination .swiper-pagination-bullet svg circle {
  stroke-width: 2;
  fill: none;
  stroke: #f3e3c1;
  stroke-dasharray: 400%;
  stroke-dashoffset: 400%;
}

.index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: none;
}

.index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  font-family: 'iconfont';
  color: #f3e3c1;
  content: "";
  margin-left: 2px;
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
    content: "";
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
    display: block;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
    width: 10px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
    height: 10px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
    border-radius: 50%;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
    background-color: #f3e3c1;
  }
}

.index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active svg circle {
  animation: 7s progress linear;
}

.index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active.restart svg circle {
  animation: 7s progress1 linear;
}

.index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active.restart2 svg circle {
  animation: 7s progress2 linear;
}

.index-banner:hover .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  content: "";
}

@media (max-width: 991px) {
  .index-banner:hover .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
    content: "";
  }
}

.index-banner:hover .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active svg circle {
  animation-play-state: paused;
}

.index-banner:hover .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active.restart svg circle {
  animation-play-state: paused;
}

.index-banner:hover .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active.restart2 svg circle {
  animation-play-state: paused;
}

.index-projects {
  padding-top: 40px;
  padding-bottom: 80px;
}

@media (max-width: 1600px) {
  .index-projects {
    padding-top: 36px;
  }
}

@media (max-width: 1359px) {
  .index-projects {
    padding-top: 32px;
  }
}

@media (max-width: 1023px) {
  .index-projects {
    padding-top: 28px;
  }
}

@media (max-width: 991px) {
  .index-projects {
    padding-top: 24px;
  }
}

@media (max-width: 767px) {
  .index-projects {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-projects {
    padding-bottom: 72px;
  }
}

@media (max-width: 1359px) {
  .index-projects {
    padding-bottom: 64px;
  }
}

@media (max-width: 1023px) {
  .index-projects {
    padding-bottom: 56px;
  }
}

@media (max-width: 991px) {
  .index-projects {
    padding-bottom: 48px;
  }
}

@media (max-width: 767px) {
  .index-projects {
    padding-bottom: 40px;
  }
}

.index-projects .index-projects-swiper {
  position: relative;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}

.index-projects .index-projects-swiper .swiper-slide {
  transform: translate3d(0, 0, 0);
}

.index-projects .index-projects-swiper .swiper-slide .item-img {
  position: relative;
  overflow: hidden;
}

.index-projects .index-projects-swiper .swiper-slide .item-img::after {
  content: '';
  position: absolute;
  z-index: 2;
  border: 2px solid #bdb29c;
  box-sizing: border-box;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  top: 40px;
  left: 40px;
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img::after {
    width: calc(100% - 60px);
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img::after {
    width: calc(100% - 40px);
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img::after {
    height: calc(100% - 60px);
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img::after {
    height: calc(100% - 40px);
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img::after {
    top: 30px;
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img::after {
    top: 20px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img::after {
    left: 30px;
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img::after {
    left: 20px;
  }
}

.index-projects .index-projects-swiper .swiper-slide .item-img > img {
  display: block;
  width: 100%;
  height: auto;
  transition: 0.4s;
}

.index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;
  text-align: center;
  background-color: #f5f1e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.4s;
}

.index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t img {
  width: 80%;
  max-width: 260px;
  height: auto;
  margin-bottom: 10%;
}

.index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t p {
  line-height: 1.4;
  margin-bottom: 7%;
  color: #5f201c;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t p {
    font-size: 16px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t p {
    font-size: 14px;
  }
}

.index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
  font-family: 'cnjt';
  display: inline-block;
  background-color: #630000;
  color: #f5f1e9;
  text-align: center;
  width: 175px;
  height: 55px;
  line-height: 55px;
}

@media (max-width: 1600px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    width: 170px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    width: 165px;
  }
}

@media (max-width: 1023px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    width: 160px;
  }
}

@media (max-width: 991px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    width: 155px;
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    width: 150px;
  }
}

@media (max-width: 1600px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    height: 52px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    height: 49px;
  }
}

@media (max-width: 1023px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    height: 46px;
  }
}

@media (max-width: 991px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    height: 43px;
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    height: 40px;
  }
}

@media (max-width: 1600px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    line-height: 52px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    line-height: 49px;
  }
}

@media (max-width: 1023px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    line-height: 46px;
  }
}

@media (max-width: 991px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    line-height: 43px;
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-slide .item-img .item-img-t span {
    line-height: 40px;
  }
}

.index-projects .index-projects-swiper .swiper-slide .item-text {
  padding: 40px;
}

@media (max-width: 1600px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text {
    padding: 35px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text {
    padding: 30px;
  }
}

@media (max-width: 1023px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text {
    padding: 25px;
  }
}

@media (max-width: 991px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text {
    padding: 15px;
  }
}

.index-projects .index-projects-swiper .swiper-slide .item-text .tit {
  font-weight: bold;
  color: #000;
  border-bottom: 1px solid #bdb29c;
  transition: 0.4s;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 26px;
}

@media (max-width: 1600px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text .tit {
    padding-bottom: 18px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text .tit {
    padding-bottom: 16px;
  }
}

@media (max-width: 1023px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text .tit {
    padding-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text .tit {
    padding-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text .tit {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text .tit {
    margin-bottom: 18px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text .tit {
    margin-bottom: 16px;
  }
}

@media (max-width: 1023px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text .tit {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text .tit {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text .tit {
    margin-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text .tit {
    font-size: 22px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text .tit {
    font-size: 20px;
  }
}

@media (max-width: 1023px) {
  .index-projects .index-projects-swiper .swiper-slide .item-text .tit {
    font-size: 16px;
  }
}

.index-projects .index-projects-swiper .swiper-slide .item-text .desc p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.index-projects .index-projects-swiper .swiper-slide:hover .item-img > img {
  transform: scale(1.05);
}

.index-projects .index-projects-swiper .swiper-slide:hover .item-img .item-img-t {
  opacity: 1;
}

@media (max-width: 991px) {
  .index-projects .index-projects-swiper .swiper-slide:hover .item-img .item-img-t {
    opacity: 0;
  }
}

.index-projects .index-projects-swiper .swiper-slide:hover .item-text .tit {
  color: #630000;
}

.index-projects .index-projects-swiper .swiper-button-next,
.index-projects .index-projects-swiper .swiper-button-prev {
  outline: none;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ddd0b5;
  color: #630000;
  transition: all 0.4s;
  width: 45px;
  height: 45px;
  top: 30%;
}

@media (max-width: 1600px) {
  .index-projects .index-projects-swiper .swiper-button-next,
  .index-projects .index-projects-swiper .swiper-button-prev {
    width: 43px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-button-next,
  .index-projects .index-projects-swiper .swiper-button-prev {
    width: 41px;
  }
}

@media (max-width: 1023px) {
  .index-projects .index-projects-swiper .swiper-button-next,
  .index-projects .index-projects-swiper .swiper-button-prev {
    width: 39px;
  }
}

@media (max-width: 991px) {
  .index-projects .index-projects-swiper .swiper-button-next,
  .index-projects .index-projects-swiper .swiper-button-prev {
    width: 37px;
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-button-next,
  .index-projects .index-projects-swiper .swiper-button-prev {
    width: 35px;
  }
}

@media (max-width: 1600px) {
  .index-projects .index-projects-swiper .swiper-button-next,
  .index-projects .index-projects-swiper .swiper-button-prev {
    height: 43px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-button-next,
  .index-projects .index-projects-swiper .swiper-button-prev {
    height: 41px;
  }
}

@media (max-width: 1023px) {
  .index-projects .index-projects-swiper .swiper-button-next,
  .index-projects .index-projects-swiper .swiper-button-prev {
    height: 39px;
  }
}

@media (max-width: 991px) {
  .index-projects .index-projects-swiper .swiper-button-next,
  .index-projects .index-projects-swiper .swiper-button-prev {
    height: 37px;
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-button-next,
  .index-projects .index-projects-swiper .swiper-button-prev {
    height: 35px;
  }
}

@media (max-width: 991px) {
  .index-projects .index-projects-swiper .swiper-button-next,
  .index-projects .index-projects-swiper .swiper-button-prev {
    top: 33%;
  }
}

.index-projects .index-projects-swiper .swiper-button-next:not(.swiper-button-disabled):hover,
.index-projects .index-projects-swiper .swiper-button-prev:not(.swiper-button-disabled):hover {
  background-color: #5f201c;
  color: #f5f1e9;
}

.index-projects .index-projects-swiper .swiper-button-next::after {
  content: '\e812';
  font-family: 'iconfont';
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-projects .index-projects-swiper .swiper-button-next::after {
    font-size: 17.6px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-button-next::after {
    font-size: 17.2px;
  }
}

@media (max-width: 1023px) {
  .index-projects .index-projects-swiper .swiper-button-next::after {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index-projects .index-projects-swiper .swiper-button-next::after {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-button-next::after {
    font-size: 16px;
  }
}

.index-projects .index-projects-swiper .swiper-button-prev::after {
  content: '\e811';
  font-family: 'iconfont';
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-projects .index-projects-swiper .swiper-button-prev::after {
    font-size: 17.6px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .swiper-button-prev::after {
    font-size: 17.2px;
  }
}

@media (max-width: 1023px) {
  .index-projects .index-projects-swiper .swiper-button-prev::after {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index-projects .index-projects-swiper .swiper-button-prev::after {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .swiper-button-prev::after {
    font-size: 16px;
  }
}

.index-projects .index-projects-swiper .index-more {
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 1600px) {
  .index-projects .index-projects-swiper .index-more {
    margin-top: 10px;
  }
}

@media (max-width: 1359px) {
  .index-projects .index-projects-swiper .index-more {
    margin-top: 10px;
  }
}

@media (max-width: 1023px) {
  .index-projects .index-projects-swiper .index-more {
    margin-top: 10px;
  }
}

@media (max-width: 991px) {
  .index-projects .index-projects-swiper .index-more {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .index-projects .index-projects-swiper .index-more {
    margin-top: 10px;
  }
}

.index-news {
  background-color: #f5f1e9;
  padding-top: 40px;
  padding-bottom: 100px;
}

@media (max-width: 1600px) {
  .index-news {
    padding-top: 36px;
  }
}

@media (max-width: 1359px) {
  .index-news {
    padding-top: 32px;
  }
}

@media (max-width: 1023px) {
  .index-news {
    padding-top: 28px;
  }
}

@media (max-width: 991px) {
  .index-news {
    padding-top: 24px;
  }
}

@media (max-width: 767px) {
  .index-news {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-news {
    padding-bottom: 90px;
  }
}

@media (max-width: 1359px) {
  .index-news {
    padding-bottom: 80px;
  }
}

@media (max-width: 1023px) {
  .index-news {
    padding-bottom: 70px;
  }
}

@media (max-width: 991px) {
  .index-news {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .index-news {
    padding-bottom: 50px;
  }
}

.index-news .index-img-news {
  background-color: #fff;
  overflow: hidden;
  padding: 45px;
}

@media (max-width: 1600px) {
  .index-news .index-img-news {
    padding: 39px;
  }
}

@media (max-width: 1359px) {
  .index-news .index-img-news {
    padding: 33px;
  }
}

@media (max-width: 1023px) {
  .index-news .index-img-news {
    padding: 27px;
  }
}

@media (max-width: 991px) {
  .index-news .index-img-news {
    padding: 21px;
  }
}

@media (max-width: 767px) {
  .index-news .index-img-news {
    padding: 15px;
  }
}

.index-news .index-img-news a {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media (max-width: 991px) {
  .index-news .index-img-news a {
    flex-direction: column;
  }
}

.index-news .index-img-news a .item-img {
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  width: 40%;
}

@media (max-width: 991px) {
  .index-news .index-img-news a .item-img {
    width: 100%;
  }
}

.index-news .index-img-news a .item-img::before {
  content: '';
  display: block;
  width: 100%;
  height: 4px;
  background: url(./images/news_img_bg.png) repeat-x top center;
  background-size: auto 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.index-news .index-img-news a .item-img::after {
  content: '';
  display: block;
  border: 1px solid #bdb29c;
  position: absolute;
  z-index: 1;
  width: calc(100% - 60px);
  height: calc(100% - 64px);
  top: 34px;
  left: 30px;
}

@media (max-width: 767px) {
  .index-news .index-img-news a .item-img::after {
    width: calc(100% - 30px);
  }
}

@media (max-width: 767px) {
  .index-news .index-img-news a .item-img::after {
    height: calc(100% - 34px);
  }
}

@media (max-width: 767px) {
  .index-news .index-img-news a .item-img::after {
    top: 19px;
  }
}

@media (max-width: 767px) {
  .index-news .index-img-news a .item-img::after {
    left: 15px;
  }
}

.index-news .index-img-news a .item-img img {
  display: block;
  width: 100%;
  height: auto;
  transition: all 0.4s;
}

.index-news .index-img-news a .item-text {
  flex-shrink: 0;
  width: 56%;
}

@media (max-width: 991px) {
  .index-news .index-img-news a .item-text {
    width: 100%;
  }
}

.index-news .index-img-news a .item-text .tit {
  color: #000;
  font-weight: bold;
  transition: all 0.4s;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 26px;
}

@media (max-width: 1600px) {
  .index-news .index-img-news a .item-text .tit {
    margin-top: 22px;
  }
}

@media (max-width: 1359px) {
  .index-news .index-img-news a .item-text .tit {
    margin-top: 19px;
  }
}

@media (max-width: 1023px) {
  .index-news .index-img-news a .item-text .tit {
    margin-top: 16px;
  }
}

@media (max-width: 991px) {
  .index-news .index-img-news a .item-text .tit {
    margin-top: 13px;
  }
}

@media (max-width: 767px) {
  .index-news .index-img-news a .item-text .tit {
    margin-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-img-news a .item-text .tit {
    margin-bottom: 13px;
  }
}

@media (max-width: 1359px) {
  .index-news .index-img-news a .item-text .tit {
    margin-bottom: 11px;
  }
}

@media (max-width: 1023px) {
  .index-news .index-img-news a .item-text .tit {
    margin-bottom: 9px;
  }
}

@media (max-width: 991px) {
  .index-news .index-img-news a .item-text .tit {
    margin-bottom: 7px;
  }
}

@media (max-width: 767px) {
  .index-news .index-img-news a .item-text .tit {
    margin-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-img-news a .item-text .tit {
    font-size: 22px;
  }
}

@media (max-width: 1359px) {
  .index-news .index-img-news a .item-text .tit {
    font-size: 20px;
  }
}

@media (max-width: 1023px) {
  .index-news .index-img-news a .item-text .tit {
    font-size: 16px;
  }
}

.index-news .index-img-news a .item-text .date {
  font-size: 14px;
  color: #5f201c;
  position: relative;
  padding-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-news .index-img-news a .item-text .date {
    padding-bottom: 18px;
  }
}

@media (max-width: 1359px) {
  .index-news .index-img-news a .item-text .date {
    padding-bottom: 16px;
  }
}

@media (max-width: 1023px) {
  .index-news .index-img-news a .item-text .date {
    padding-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-news .index-img-news a .item-text .date {
    padding-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-news .index-img-news a .item-text .date {
    padding-bottom: 10px;
  }
}

.index-news .index-img-news a .item-text .date::after {
  content: '';
  display: block;
  height: 2px;
  background-color: #bdb29c;
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 200%;
  left: -11%;
}

@media (max-width: 991px) {
  .index-news .index-img-news a .item-text .date::after {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .index-news .index-img-news a .item-text .date::after {
    left: 0;
  }
}

.index-news .index-img-news a .item-text .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 4;
  margin-top: 50px;
}

@media (max-width: 1359px) {
  .index-news .index-img-news a .item-text .desc {
    -webkit-line-clamp: 3;
  }
}

@media (max-width: 1600px) {
  .index-news .index-img-news a .item-text .desc {
    margin-top: 42px;
  }
}

@media (max-width: 1359px) {
  .index-news .index-img-news a .item-text .desc {
    margin-top: 34px;
  }
}

@media (max-width: 1023px) {
  .index-news .index-img-news a .item-text .desc {
    margin-top: 26px;
  }
}

@media (max-width: 991px) {
  .index-news .index-img-news a .item-text .desc {
    margin-top: 18px;
  }
}

@media (max-width: 767px) {
  .index-news .index-img-news a .item-text .desc {
    margin-top: 10px;
  }
}

.index-news .index-img-news a:hover .item-img img {
  transform: scale(1.05);
}

.index-news .index-img-news a:hover .item-text .tit {
  color: #630000;
}

.index-news .index-news-list {
  margin-top: 60px;
}

@media (max-width: 1600px) {
  .index-news .index-news-list {
    margin-top: 54px;
  }
}

@media (max-width: 1359px) {
  .index-news .index-news-list {
    margin-top: 48px;
  }
}

@media (max-width: 1023px) {
  .index-news .index-news-list {
    margin-top: 42px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-list {
    margin-top: 36px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-list {
    margin-top: 30px;
  }
}

.index-news .index-news-list ul {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}

.index-news .index-news-list ul li {
  margin-bottom: 15px;
  width: 31%;
}

@media (max-width: 991px) {
  .index-news .index-news-list ul li {
    width: 100%;
  }
}

.index-news .index-news-list ul li a {
  display: flex;
}

.index-news .index-news-list ul li a .item-date {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  background-color: #5f201c;
  box-shadow: 4px 4px 0 #d9d1c2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.1;
  color: #b69a95;
  transition: all 0.4s;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .index-news .index-news-list ul li a .item-date {
    font-size: 18px;
  }
}

@media (max-width: 1359px) {
  .index-news .index-news-list ul li a .item-date {
    font-size: 16px;
  }
}

.index-news .index-news-list ul li a .item-date span {
  color: #f5f1e9;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  border-bottom: 1px solid rgba(243, 227, 193, 0.4);
}

.index-news .index-news-list ul li a .item-text {
  padding-left: 20px;
}

.index-news .index-news-list ul li a .item-text .tit {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.5;
  color: #000;
  transition: all 0.4s;
  margin-bottom: 25px;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .index-news .index-news-list ul li a .item-text .tit {
    margin-bottom: 21px;
  }
}

@media (max-width: 1359px) {
  .index-news .index-news-list ul li a .item-text .tit {
    margin-bottom: 17px;
  }
}

@media (max-width: 1023px) {
  .index-news .index-news-list ul li a .item-text .tit {
    margin-bottom: 13px;
  }
}

@media (max-width: 991px) {
  .index-news .index-news-list ul li a .item-text .tit {
    margin-bottom: 9px;
  }
}

@media (max-width: 767px) {
  .index-news .index-news-list ul li a .item-text .tit {
    margin-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .index-news .index-news-list ul li a .item-text .tit {
    font-size: 18px;
  }
}

@media (max-width: 1359px) {
  .index-news .index-news-list ul li a .item-text .tit {
    font-size: 16px;
  }
}

.index-news .index-news-list ul li a .item-text .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.index-news .index-news-list ul li a:hover .item-date {
  box-shadow: 0px 0px 0 #d9d1c2;
  background-color: #630000;
}

.index-news .index-news-list ul li a:hover .item-text .tit {
  color: #630000;
}

.index-news .index-news-list ul .placeholder {
  width: 31%;
}

@media (max-width: 991px) {
  .index-news .index-news-list ul .placeholder {
    width: 100%;
  }
}

.index-news .index-more {
  text-align: center;
  margin-top: 50px;
}

@media (max-width: 1600px) {
  .index-news .index-more {
    margin-top: 44px;
  }
}

@media (max-width: 1359px) {
  .index-news .index-more {
    margin-top: 38px;
  }
}

@media (max-width: 1023px) {
  .index-news .index-more {
    margin-top: 32px;
  }
}

@media (max-width: 991px) {
  .index-news .index-more {
    margin-top: 26px;
  }
}

@media (max-width: 767px) {
  .index-news .index-more {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1440px) {
  .index-news .index-img-news a .item-text .date:after {
    left: -12%;
  }
}

@media screen and (max-width: 1024px) {
  .index-news .index-img-news a .item-text .date:after {
    left: -13.5%;
  }
}

@media screen and (max-width: 1024px) and (max-width: 991px) {
  .index-news .index-img-news a .item-text .date:after {
    left: 0;
  }
}
