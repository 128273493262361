@import "../../assets/styles/utils";

.index-banner{
    .swiper-container{
        overflow: visible;
    }
    .swiper-wrapper{
        .swiper-slide{
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            @include res(height,755px, (lg:640px,mmd:410px,md:400px,sm:512px,xs:250px));
            a{
                display: block;
                width: 100%;
                height: 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                &.pc-banner{
                    @include res(display,block,(sm:none));
                }
                &.mob-banner {
                  @include res(display, none, (sm:block));
                }
            }
            img {
              display: block;
              width: 100%;
              height: auto;
            }
        }
        
    }
    .swiper-pagination{
        display: flex;
        justify-content: center;
        .swiper-pagination-bullet{
            opacity: 1;
            background: none;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            @include res(width,40px,(sm:15px));
            @include res(height, 40px, (sm:15px));
            &:not(.swiper-pagination-bullet-active)::after {
              content: '';
              display: inline-block;
              // width: 8px;
              // height: 8px;
              border-radius: 50%;
              background-color: #f3e3c1;
              @include res(width,8px,(xs:5px));
              @include res(height, 8px, (xs:5px));
            }
            svg {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              transform-origin: center;
              transform-box: fill-box;
              transform: rotate(-90deg);

              circle {
                stroke-width: 2;
                fill: none;
                stroke: #f3e3c1;
                stroke-dasharray: 400%;
                stroke-dashoffset: 400%;
                
              }
            }
            &.swiper-pagination-bullet-active{
                background: none;
                &::after {
                  font-family: 'iconfont';
                  color: #f3e3c1;
                  @include res(content, '\e60a', (sm:''));
                  @include res(margin-left, 2px, (sm:0));
                  @include res(display, null, (sm:block));
                  @include res(width, null, (sm:10px));
                  @include res(height, null, (sm:10px));
                  @include res(border-radius, null, (sm:50%));
                  @include res(background-color, null, (sm: #f3e3c1));
                }
                svg{
                    circle{
                        animation:7s progress linear;
                    }
                }
                &.restart{
                    svg {
                      circle {
                        animation: 7s progress1 linear;
                      }
                    }
                }
                &.restart2 {
                  svg {
                    circle {
                      animation: 7s progress2 linear;
                    }
                  }
                }
            }
        }
    }
    &:hover{
        .swiper-pagination{
            .swiper-pagination-bullet {
                &.swiper-pagination-bullet-active {
                    &::after {
                      @include res(content, '\e810',(sm:''));
                    }
                    svg {
                        circle {
                            animation-play-state:paused; //暂停动画
                        }
                    }
                    &.restart {
                      svg {
                        circle {
                          animation-play-state:paused; //暂停动画
                        }
                      }
                    }
                    &.restart2 {
                      svg {
                        circle {
                          animation-play-state: paused; //暂停动画
                        }
                      }
                    }
                }
            }
        }
    }
}

.index-projects{
    @include res(padding-top, 40px, 20 / 40);
    @include res(padding-bottom, 80px, 40 /80);
    .index-projects-swiper {
        position: relative;
        transform:translate3d(0,0,0);
        overflow:hidden;
        .swiper-slide{
            transform:translate3d(0,0,0);
            .item-img{
                position: relative;
                overflow: hidden;
                &::after{
                    content: '';
                    position: absolute;
                    z-index: 2;
                    border: 2px solid $color-line;
                    box-sizing: border-box;
                    @include res(width, calc(100% - 80px), (mmd:calc(100% - 60px), xs:calc(100% - 40px)));
                    @include res(height, calc(100% - 80px), (mmd:calc(100% - 60px), xs:calc(100% - 40px)));
                    @include res(top, 40px, (mmd:30px, xs:20px));
                    @include res(left, 40px, (mmd:30px, xs:20px));
                }
                >img{
                    display: block;
                    width: 100%;
                    height: auto;
                    transition: $anime-duration;
                }
                .item-img-t{
                    width: calc(100% - 60px);
                    height: calc(100% - 60px);
                    position: absolute;
                    top: 30px;
                    left: 30px;
                    z-index: 1;
                    text-align: center;
                    background-color: $color-main-bg;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    opacity: 0;
                    transition: $anime-duration;
                    img{
                        width: 80%;
                        max-width: 260px;
                        height: auto;
                        margin-bottom: 10%;
                    }
                    p{
                        line-height: 1.4;
                        margin-bottom: 7%;
                        color: $color-main;
                        @include res(font-size, $text-size-md-base, $text-size-md-ratio);
                    }
                    span{
                        font-family: 'cnjt';
                        display: inline-block;
                        background-color: $color-main-light;
                        color: $color-main-bg;
                        text-align: center;
                        @include res(width,175px, 150 / 175);
                        @include res(height,55px, 40 / 55);
                        @include res(line-height, 55px, 40 / 55);
                    }
                }
            }
            .item-text {
                @include res(padding, 40px, 15 / 40);
                .tit{
                    font-weight: bold;
                    color: $color-title;
                    border-bottom: 1px solid $color-line;
                    transition: $anime-duration;
                    @include res(padding-bottom,20px, 10 / 20);
                    @include res(margin-bottom, 20px, 10 / 20);
                    @include res(font-size, $title-size-sm-base, $title-size-sm-ratio);
                }
                .desc{
                    p{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
            &:hover{
                .item-img{
                    >img{
                        transform: scale(1.05);
                    }
                    .item-img-t{
                        @include res(opacity,1,(sm:0));
                    }
                }
                .item-text{
                    .tit{
                        color: $color-main-light;
                    }
                }
            }
        }
        .swiper-button-next,
        .swiper-button-prev{
            outline: none;
            border-radius: 50%;
            overflow: hidden;
            background-color: #ddd0b5;
            color: $color-main-light;
            transition: all $anime-duration;
            @include res(width,45px, 35 / 45);
            @include res(height, 45px, 35 / 45);
            @include res(top, 30%, (sm:33%));
            &:not(.swiper-button-disabled):hover{
                background-color:$color-main;
                color: $color-main-bg;
            }
        }
        .swiper-button-next{
            &::after{
                content: '\e812';
                font-family: 'iconfont';
                @include res(font-size,18px, 16 / 18);
            }
        }
        .swiper-button-prev{
            &::after {
              content: '\e811';
              font-family: 'iconfont';
              @include res(font-size, 18px, 16 / 18);
            }
        }
        .index-more{
            text-align: center;
            @include res(margin-top, 10px, 10 /10);
        }
    }
}
.index-news{
    background-color: $color-main-bg;
    @include res(padding-top, 40px, 20 / 40);
    @include res(padding-bottom,100px,50 / 100);
    .index-img-news{
        background-color: #fff;
        overflow: hidden;
        @include res(padding, 45px, 15 / 45);
        a{
            display: flex;
            justify-content: space-between;
            @include res(flex-direction,row,(sm:column));
            .item-img{
                flex-shrink: 0;
                position: relative;
                overflow: hidden;
                @include res(width, 40%, (sm:100%));
                &::before{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 4px;
                    background: url(./images/news_img_bg.png) repeat-x top center;
                    background-size: auto 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }
                &::after {
                  content: '';
                  display: block;
                  border: 1px solid $color-line;
                  position: absolute;
                  z-index: 1;
                  @include res(width, calc(100% - 60px), (xs:calc(100% - 30px)));
                  @include res(height, calc(100% - 64px), (xs:calc(100% - 34px)));
                  @include res(top, 34px, (xs:19px));
                  @include res(left, 30px, (xs:15px));
                }
                img{
                    display: block;
                    width: 100%;
                    height: auto;
                    transition: all $anime-duration;
                }
            }
            .item-text {
              flex-shrink: 0;
              @include res(width, 56%, (sm:100%));
              .tit{
                  color: $color-title;
                  font-weight: bold;
                  transition: all $anime-duration;
                  @include res(margin-top, 25px, 10 / 25);
                  @include res(margin-bottom, 15px, 5 / 15);
                  @include res(font-size, $title-size-sm-base, $title-size-sm-ratio);
              }
              .date{
                  font-size: 14px;
                  color: $color-main;
                  position: relative;
                  @include res(padding-bottom, 20px, 10 / 20);
                  &::after {
                    content: '';
                    display: block;
                    height: 2px;
                    background-color: $color-line;
                    position: absolute;
                    bottom: 0;
                    z-index: 1;
                    @include res(width, 200%, (sm:100%));
                    @include res(left, -11%, (sm:0));
                  }
              }
              .desc{
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  @include res(-webkit-line-clamp, 4, (mmd:3));
                  @include res(margin-top, 50px, 10 / 50);
              }
            }
            &:hover{
                .item-img{
                    img{
                        transform: scale(1.05);
                    }
                }
                .item-text{
                    .tit{
                        color: $color-main-light;
                    }
                }
            }
        }
    }
    .index-news-list{
        @include res(margin-top, 60px, 30 / 60);
        ul{
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            li{
                margin-bottom: 15px;
                @include res(width,31%,(sm:100%));
                a{
                    display: flex;
                    .item-date{
                        flex-shrink: 0;
                        width: 60px;
                        height: 60px;
                        background-color: $color-main;
                        box-shadow: 4px 4px 0 #d9d1c2;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        line-height: 1.1;
                        color: #b69a95;
                        transition: all $anime-duration;
                        @include res(font-size, $text-size-lg-base, $text-size-lg-ratio);
                        span{
                            color: #f5f1e9;
                            font-size: 14px;
                            font-weight: bold;
                            line-height: 1.4;
                            border-bottom: 1px solid $color-line-rgba;
                        }
                    }
                    .item-text{
                        padding-left: 20px;
                        .tit{
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            line-height: 1.5;
                            color: $color-title;
                            transition: all $anime-duration;
                            @include res(margin-bottom, 25px, 5 / 25);
                            @include res(font-size, $text-size-lg-base, $text-size-lg-ratio);
                        }
                        .desc {
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 2;
                          overflow: hidden;
                        }
                    }
                    &:hover{
                        .item-date{
                            box-shadow: 0px 0px 0 #d9d1c2;
                            background-color: $color-main-light;
                        }
                        .item-text{
                            .tit{
                                color: $color-main-light;
                            }
                        }
                    }
                }
            }
            .placeholder{
                @include res(width, 31%, (sm:100%));
            }
        }
    }
    .index-more {
      text-align: center;
      @include res(margin-top, 50px, 20 /50);
    }
}

@media screen and (max-width: 1440px) {
    .index-news .index-img-news a .item-text .date:after{
        left: -12%;
    }
}
@media screen and (max-width: 1024px) {
  .index-news .index-img-news a .item-text .date:after {
    @include res(left, -13.5%, (sm:0));
  }
}